import React, { useEffect, useCallback, useState } from "react";
import { welcomeIcon } from "helpers/pageIcons";
import { Redirect } from "react-router-dom";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import FooterContainer from "containers/FooterContainer";
import { connect } from "react-redux";
import { Textarea, GutterField, Button } from "design-system-react";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import TextareaWrap from "./components/TextareaWrap";
function GoLive({ token_widget, brandColor, setupId }) {
  const [isNext, setNext] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [canPublish, setCanPublish] = useState(true);

  useEffect(() => {
    document.title = "Go live";
  }, []);

  const handleSubmitPublish = useCallback(() => {
    setLoading(true);
    api.setups
      .publishSetup(setupId)
      .ready.then(response => {
        if (response.data.data.published) {
          setCanPublish(false);
        }
        showAlert(
          ALERT_SUCCESS,
          "The changes have been published successfully"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleBackClick = useCallback(() => {
    setNext(true);
  }, []);

  if (isNext) {
    return <Redirect to={"/setups/" + setupId + "/update-qualify"} />;
  }

  return (
    <div>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={welcomeIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Go live</HeaderCardTitle>
          <HeaderCardDescription>
            Thank you for successfully setting up the widget. Copy the source
            code to paste into the site.
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <GutterField>
        <TextareaWrap>
          <Textarea
            name="token"
            label="API Token"
            disabled
            value={token_widget}
          />
        </TextareaWrap>
      </GutterField>
      <GutterField>
        <TextareaWrap>
          <Textarea
            name="code"
            label="Widget code"
            disabled
            value={`<script>var BLINGER_ID="${setupId}";(function (d) {var s = d.createElement("script");s.async = true;s.charset="utf-8";s.src = "https://widget-test.blinger.armtek.by/widget/blinger-widget.js";d.head.appendChild(s);})(window.document);</script>`}
          />
        </TextareaWrap>
      </GutterField>
      <Button
        processing={isLoading}
        disabled={!canPublish}
        onClick={handleSubmitPublish}
      >
        Publish
      </Button>
      <FooterContainer onBackClick={handleBackClick} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token_widget: state.setup.current.token_widget,
    setupId: state.setup.current.id,
    brandColor: state.setup.current.style.brandColor || "#999"
  };
};

export default connect(mapStateToProps)(GoLive);
